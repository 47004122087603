<template>
  <div class="base-table">
    <div class="action">
      <slot name="action"></slot>
    </div>
    <el-table
    v-bind="$attrs">
      <template v-for="item in columns" :key="item.prop">
        <el-table-column
        type="selection"
        width="55"
        v-if="item.type == 'selection'"
        key="selection"
        ></el-table-column>
        <el-table-column
          v-bind="item"
          v-else-if="item.type != 'action'">
        </el-table-column>
        <el-table-column v-if="item.type == 'action'" v-bind="item">
          <template #default="scope">
            <template v-for="(btn, index) in item.list" :key="index">
              <el-button
                :type="btn.type || 'text'"
                size="mini"
                @click="handleAction(index,scope.row)"
                v-if="btn.visible"
              >
              {{btn.text}}
              </el-button>
            </template>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      class="pagination"
      background
      layout="prev,pager,next"
      :total="pager.total"
      :page-size="pager.pageSize"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseTable',
  props: ['columns', 'pager'],
  setup (props, context) {
    // 列按钮点击处理
    const handleAction = (index, row) => {
      context.emit('handleAction', { index, row: { ...row } })
    }
    // 翻页处理
    const handleCurrentChange = (pageNum) => {
      context.emit('handleCurrentChange', pageNum)
    }
    return {
      handleAction,
      handleCurrentChange
    }
  }
}
</script>

<style>

</style>