<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item v-for="(item,index) in breadList" :key="item.path">
      <router-link to="/welcome" v-if="index==0">{{item.meta.title}}</router-link>
      <span v-else>{{item.meta.title}}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { ElBreadcrumb } from 'element-plus'
export default {
  name: 'BreadCrumb',
  components: {
    ElBreadcrumb,
    ElBreadcrumbItem:ElBreadcrumb.BreadcrumbItem
  },
  computed: {
    breadList () {
      return this.$route.matched
    }
  },
  mounted () {
    console.log('routes=>', this.$route)
  }
}
</script>

<style>

</style>
