import { createRouter, createWebHashHistory } from 'vue-router'
import Home from './../components/Home.vue'
import Iframe from './../components/Iframe.vue'
import storage from './../utils/storage'



const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: Home,
    redirect: '/welcome',
    children: [
      {
        name: 'Welcome',
        path: '/welcome',
        meta: {
          title: '欢迎使用票景通运营管理平台'
        },
        component: () => import('./../views/Welcome.vue')
      },
      {
        name: 'boatManage',
        path: '/product/manager',
        meta: {
          title: '产品管理'
        },
        component: () => import('./../views/boatTicket/WharfManager.vue')
      },
      // 测试代码
      // {
      //   name: 'distributorManage',
      //   path: '/distributor/manager',
      //   meta: {
      //     title: '船票管理',
      //     url: 'https://www.imooc.com/'
      //   },
      //   component: Iframe
      // },
      // ------- B2B业务 ----------
      {
        name: 'searchOrder',
        path: '/btob/search/orderlist',
        meta: {
          title: '订单查询',
          // 'http://192.168.1.165:8080/#/order'
          url:  process.env.VUE_APP_MANAGE_URL + 'order'
        },
        component: Iframe
      },
      {
        name: 'newSearchOrder',
        path: '/btob/search/newOrderlist',
        meta: {
          title: '订单查询(新)',
          // 'http://192.168.1.165:8080/#/order'
          url: process.env.VUE_APP_MANAGE_URL + 'new/order'
        },
        component: Iframe
      },
      {
        name: 'BtoboverTimeRefund',
        path: '/btob/overtime/refund/list',
        meta: {
          title: '客诉退票记录',
          // 'http://192.168.1.165:8080/#/order'
          url:  process.env.VUE_APP_MANAGE_URL + 'overtime/refundList'
        },
        component: Iframe
      },
      {
        name: 'jcsxOrderList',
        path: '/btob/jcsx/orderlist',
        meta: {
          title: '京城水系订单查询',
          // 'http://192.168.1.165:8080/#/order'
          url:  process.env.VUE_APP_MANAGE_URL + 'jcsx/order/list?tenantCode=jingchengshuixi'
        },
        component: Iframe
      },
      {
        name: 'zyOrderList',
        path: '/btob/zy/orderlist',
        meta: {
          title: '中宇订单查询',
          // 'http://192.168.1.165:8080/#/order'
          url:  process.env.VUE_APP_MANAGE_URL + 'jcsx/order/list?tenantCode=zhongyu'
        },
        component: Iframe
      },
      {
        name: 'yhgjOrderList',
        path: '/btob/yhgj/orderlist',
        meta: {
          title: '远海国际订单查询',
          // 'http://192.168.1.165:8080/#/order'
          url:  process.env.VUE_APP_MANAGE_URL + 'jcsx/order/list?tenantCode=yhgj'
        },
        component: Iframe
      },
      // -------- 云票相关页面 ------------
      {
        name: 'ticketManage',
        path: '/ticket/manage',
        meta: {
          title: '门票管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'ticket'
        },
        component: Iframe
      },
      // {
      //   name: 'searchOrderDetail',
      //   path: '/btob/search/orderdetail',
      //   meta: {
      //     title: '订单查询',
      //     // 'http://192.168.1.165:8080/#/order'
      //     url:  process.env.VUE_APP_MANAGE_URL + 'orderdetail'
      //   },
      //   component: Iframe
      // },
      // ------------ 综合服务窗口业务 ---------------
      {
        name: 'exchangeOrder',
        path: '/exchange/ticket/index',
        meta: {
          title: '查询换票',
          url: 'http://192.168.1.165:8081/#/exchange/orderlist'
          // url:  process.env.VUE_APP_MANAGE_URL + 'order'
        },
        component: Iframe
      },
      // ------------ 云票运营 ---------------
      {
        name: 'marketingCalendar',
        path: '/marketing/calendar',
        meta: {
          title: '营销日历',
          url: process.env.VUE_APP_OPERATE_URL + 'marketing/calendar/list'
          // url:  process.env.VUE_APP_MANAGE_URL + 'order'
        },
        component: Iframe
      },
      {
        name: 'marketingPeriodic',
        path: 'marketing/periodic',
        meta: {
          title: '周期性营销内容',
          url: process.env.VUE_APP_OPERATE_URL + 'marketing/periodic/list'
          // url:  process.env.VUE_APP_MANAGE_URL + 'order'
        },
        component: Iframe
      },
      {
        name: 'stockList',
        path: '/update/stock/list',
        meta: {
          title: '库存管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list'
        },
        component: Iframe
      },
      {
        name: 'calendarIndex',
        path: '/calendar/list',
        meta: {
          title: '库存日历',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_OPERATE_URL + 'calendar/index'
        },
        component: Iframe
      },
      {
        name: 'batchPushOrder',
        path: '/batch/push/order',
        meta: {
          title: '批量上单',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_CHANNEL_MARKETING_URL + 'reserve'
        },
        component: Iframe
      },
      {
        name: 'foreignerPushOrder',
        path: '/foreigner/push/order',
        meta: {
          title: '外籍批量上单',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_CHANNEL_MARKETING_URL + 'wgrreserve'
        },
        component: Iframe
      },
      {
        name: 'ttPushOrder',
        path: '/ttpark/push/order',
        meta: {
          title: '外籍批量上单',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url: process.env.VUE_APP_CHANNEL_MARKETING_URL + 'ttreserve'
        },
        component: Iframe
      },
      {
        name: 'ttforeignerPushOrder',
        path: '/ttpark/foreigner/push/order',
        meta: {
          title: '外籍批量上单',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url: process.env.VUE_APP_CHANNEL_MARKETING_URL + 'ttwgrreserve'
        },
        component: Iframe
      },
      {
        name: 'ttchecksearch',
        path: '/ttpark/check/search/list',
        meta: {
          title: '验票信息查询',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url: process.env.VUE_APP_CHANNEL_MARKETING_URL + 'ttcheck/list'
        },
        component: Iframe
      },
      {
        name: 'batchRefund',
        path: '/ticketOrder/batchRefund',
        meta: {
          title: '批量退票',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_OPERATE_URL + 'ticketOrder/list'
        },
        component: Iframe
      },
      {
        name: 'supplier',
        path: '/supplier/index',
        meta: {
          title: '供应商管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'supplier/list'
        },
        component: Iframe
      },
      {
        name: 'distributor',
        path: '/distributor/index',
        meta: {
          title: '分销商管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'distributor/list'
        },
        component: Iframe
      },
      {
        name: 'contract',
        path: '/contract/index',
        meta: {
          title: '合同管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'contract/list'
        },
        component: Iframe
      },
      {
        name: 'goods',
        path: '/goods/index',
        meta: {
          title: '商品管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'goods/list'
        },
        component: Iframe
      },
      {
        name: 'product',
        path: '/product/index',
        meta: {
          title: '产品管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'product/list'
        },
        component: Iframe
      },
      {
        name: 'settlement',
        path: '/settlement/index',
        meta: {
          title: '结算单管理',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_MANAGE_URL + 'settlement/list'
        },
        component: Iframe
      },
      {
        name: 'batchRefund',
        path: '/ticketOrder/batchRefund',
        meta: {
          title: '批量退票',
          // url:'http://localhost:8080/operate/#/update/stock/list'
          // url:  process.env.VUE_APP_OPERATE_URL + 'update/stock/list?token=' + token
          url:  process.env.VUE_APP_OPERATE_URL + 'ticketOrder/list'
        },
        component: Iframe
      },
      {
        name: 'messagedatahis',
        path: '/messagedatahis/index',
        meta: {
          title: '消息数据历史',
          url:  process.env.VUE_APP_SEA_MONSTER_URL + 'messagedatahis/list'
        },
        component: Iframe
      },
      {
        name: 'transdatahis',
        path: '/transdatahis/index',
        meta: {
          title: '业务数据历史',
          url:  process.env.VUE_APP_SEA_MONSTER_URL + 'transdatahis/list'
        },
        component: Iframe
      },
      {
        name: 'reconciliationStatistics',
        path: '/reconciliation/statistics',
        meta: {
          title: '交易账单统计',
          url: process.env.VUE_APP_OPERATE_URL + 'transaction/reconciliation/statistics'
        },
        component: Iframe
      },
      {
        name: 'reconciliationCheckStatistics',
        path: '/reconciliation/check/statistics',
        meta: {
          title: '验票统计',
          url: process.env.VUE_APP_OPERATE_URL + 'transaction/reconciliation/check/statistics'
        },
        component: Iframe
      },
      {
        name: 'b2bCommonIndex',
        path: '/b2b/common/index',
        meta: {
          title: '共通主表',
          url: process.env.VUE_APP_OPERATE_URL + 'common/index'
        },
        component: Iframe
      },

      {
        name: 'b2bReconciliationConfig',
        path: '/b2b/reconciliation/config',
        meta: {
          title: '对账配置',
          url: process.env.VUE_APP_OPERATE_URL + 'reconciliation/config'
        },
        component: Iframe
      },

      {
        name: 'b2bManualbilling',
        path: '/b2b/manualbilling/index',
        meta: {
          title: '人工出账单',
          url: process.env.VUE_APP_OPERATE_URL + 'manualbilling/index'
        },
        component: Iframe
      },
      {
        name: 'y3spAccountBillImport',
        path: '/y3spaccount/billimport/index',
        meta: {
          title: '分销商账单导入',
          url: process.env.VUE_APP_OPERATE_URL + 'y3spaccount/billimport/index'
        },
        component: Iframe
      },
      {
        name: 'y3spAccountAccountlist',
        path: '/y3spaccount/accountlist/index',
        meta: {
          title: '分销商对账列表',
          url: process.env.VUE_APP_OPERATE_URL + 'y3spaccount/accountlist/index'
        },
        component: Iframe
      },
    ]
  },
  {
    name: 'login',
    path: '/login',
    meta: {
      title: '登录'
    },
    component: () => import('./../views/Login.vue')
  },
  {
    name: 'tofu',
    path: '/tofu',
    meta: {
      title: '豆腐块页'
    },
    component: () => import('./../views/Tofu.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.path != '/login' && storage.getItem('userInfo') == undefined) {
    next({path: '/login'})
  } else {
    // console.log(storage.getItem('userInfo'))
    // if (storage.getItem('userInfo') != undefined) {
    //   const { access_token } = storage.getItem('userInfo')
    //   if (access_token) {
    //     to.meta.url = to.meta.url + '?token=' + access_token
    //   }
    // }
    // const { access_token } = storage.getItem('userInfo')
    // storage.getItem('userInfo').access_token
    // if (access_token) {
    //   to.meta.url = to.meta.url + '?token=' + access_token
    // }
    // console.log("-------------外层token---------------")
    // console.log(token)
    // console.log("-------------外层token---------------")
    next()
  }
//  const { access_token } = storage.getItem('userInfo')
//   if (access_token) {

//     next({path: '/login'})
//   } else {

//     next()
//   }
})

export default router
