import request from '../utils/request'
export default {
  // login (params) {
  //   request({
  //     url: '/users/login',
  //     method:'post',
  //     data: params,
  //   })
  // },
  // getMenuList () {
  //   request({
  //     url: '/menu/list',
  //     method:'get',
  //     data: {},
  //   })
  // },
  getWharfList (params) {
    return request({
      url: '/wharfInfo/list',
      method: 'post',
      data: params
    })
  },
  addWharfList (params) {
    return request({
      url: '/wharfInfo/create',
      method: 'post',
      data: params
    })
  },
  delWharfList (params) {
    return request({
      url: '/wharfInfo/delete',
      method: 'post',
      data: params
    })
  }
}
