<template>
  <template v-for="menu in userMenu">
    <el-submenu v-if="menu.children && menu.children.length>0" :key="menu.id" :index="menu.path">
      <template #title>
          <i class="el-icon-setting"></i>
          <span>{{menu.meta.title}}</span>
      </template>
      <tree-menu :userMenu="menu.children"/>
    </el-submenu>
    <el-menu-item v-else :index="menu.path" :key="menu.id">
        {{menu.meta.title}}
    </el-menu-item>
  </template>
</template>

<script>
import {
  ElMenu
} from 'element-plus'
export default {
  components: {
    ElSubmenu:ElMenu.SubMenu,
    ElMenuItem: ElMenu.MenuItem,
  },
  name: 'TreeMenu',
  props: {
    userMenu: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style>

</style>