const devConf = [
  {
    client_id: 'admin',
    client_secret: 'secret'
  }
]
const testConf = [
  {
    client_id: 'admin',
    client_secret: 'secret'
  }
]
const proConf = [
  {
    client_id: 'admin',
    client_secret: 'secret'
  }
]
const uatConf = [
  {
    client_id: 'admin',
    client_secret: 'secret'
  }
]

export function getConf () {
  if (process.env.VUE_APP_ENV == 'development') {
    return devConf[0]
  } else if (process.env.VUE_APP_ENV == 'production') {
    return proConf[0]
  }else if (process.env.VUE_APP_ENV == 'test') {
    return testConf[0]
  }else if (process.env.VUE_APP_ENV == 'uat') {
    return uatConf[0]
  }
  
}