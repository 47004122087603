import http from './../utils/http'

const post = (url, data) => {
  return http.post(
    url,
    data
  )
}

// eslint-disable-next-line no-unused-vars
const get = (url, params = {}) => {
  http.get(
    url,
    params
  )
}

// const getAccessToken = data => post(`/oauth/token?client_id=admin&client_secret=secret&grant_type=password&username=${data.userName}&password=${data.userPwd}`, {})
const getAccessToken = (data,config) => post(`/oauth/token?client_id=${config.client_id}&client_secret=${config.client_secret}&grant_type=password&username=${data.userName}&password=${data.userPwd}`, {})

const getRefreshToken = (data,config)=> post(`/oauth/token?grant_type=refresh_token&refresh_token=${data.refreshToken}&client_id=${config.client_id}&client_secret=${config.client_secret}`, {})

const getMenuList = data => post('/lyre/user/authority/get', data)

const getTofuList = data => post('/manage/public/user/navigation/permission/get', data)

const getMainMenuList = data => post('/manage/public/user/permission/get', data)

export { getAccessToken, getRefreshToken, getMenuList, getTofuList, getMainMenuList }
