<template>
  <div class="basic-layout">
    <div :class="['nav-side',isCollapse?'fold':'unfold']">
      <!-- 系统logo -->
      <div class="logo">
        <img src="./../assets/pjt.png" alt=""/>
        <span style="font-size:14px">票景通运营管理平台</span>
        <!-- <img v-if="parkCode == 'B2B'" src="./../assets/pjt.png" alt="">
        <img v-else src="./../assets/logo.png" alt="">
        <span style="font-size:14px" v-if="parkCode == 'B2B'">票景通运营管理平台</span>
        <span v-else>云票系统</span> -->
      </div>
      <!-- 导航菜单 -->
      <el-menu
      :default-active="activeMenu"
      background-color="#001529"
      text-color="#fff"
      router
      :collapse="isCollapse"
      class="nav-menu"
      >
      <tree-menu :userMenu="userMenu"/>
    </el-menu>
    </div>
    <div :class="['content-right',isCollapse?'fold':'unfold']">
      <div class="nav-top">
        <div class="nav-left">
          <div class="menu-fold" @click="toggle"><i class="el-icon-s-fold"></i></div>
          <div class="bread">
            <BreadCrumb/>
          </div>
        </div>
        <div class="user-info">
          <span class="user-icon"><i class="el-icon-user-solid"></i></span>
          <el-dropdown @command="hanldeCommand">
            <span class="user-link">
              {{userName}}
            <i class="el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="userInfo">个人信息</el-dropdown-item>
                <el-dropdown-item command="logout">退出</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
      <div class="wrapper">
        <!-- <router-view/> -->
        <router-view v-slot="{ Component }">
          <keep-alive >
            <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
          </keep-alive>
          <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TreeMenu from './TreeMenu.vue'
// import { getMenuList } from './../api/getway'
import { getMainMenuList } from './../api/getway'
import { ElMessage } from 'element-plus'
import BreadCrumb from './BreadCrumb.vue'
import storage from './../utils/storage'
import {
  ElMenu,
  ElDropdown
} from 'element-plus'
export default {
  name: 'home',
  components: { TreeMenu, BreadCrumb,ElMenu,ElDropdown,ElDropdownMenu:ElDropdown.DropdownMenu,ElDropdownItem:ElDropdown.DropdownItem },
  data () {
    return {
      activeMenu: location.hash.slice(1),
      isCollapse: false,
      // userInfo: {
      //   userName: 'admin'
      // },
      userName: '',
      userMenu: [],
      parkCode: ''
    }
  },
  mounted () {
    let id = this.$route.query.id
    if (id) {
      storage.setItem('moduleId', id)
    } else {
      id = storage.getItem('moduleId')
    }
    this.parkCode = localStorage.getItem('parkCode')
    console.log(this.parkCode)
    this.getMenuList(id)
  },
  methods: {
    toggle () {
      this.isCollapse = !this.isCollapse
    },
    hanldeCommand (key) {
      if (key == 'logout') {
        this.$store.commit('saveUserInfo', '')
        this.userInfo = null
        this.$router.push('/login')
      } else {
        return false
      }
    },
    async getMenuList (id) {
      const res = await getMainMenuList()
      this.userName = res.data.user.realName
      const AllMeun = res.data.permission
      const moduleMeun = AllMeun.filter(item => item.id === id)
      const moduleMeunObj = moduleMeun[0]
      console.log(moduleMeunObj)
      if (moduleMeunObj.children) {
        this.userMenu = moduleMeunObj.children
      } else {
        this.userMenu = []
      }
    }
    // async getMenuList () {
    //   try {
    //     const userInfo = storage.getItem('userInfo')
    //     const token = userInfo.access_token
    //     const params = {
    //       accessToken: token
    //     }
    //     const { data } = await getMenuList(params)
    //     console.log(data)
    //     this.userMenu = data.permission
    //     this.userName = data.user.realName
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }
  }
}
</script>

<style lang='scss' scoped>
.basic-layout{
  position: relative;
  .nav-side{
    position: fixed;
    width: 200px;
    height: 100vh;
    background-color: #001529;
    color: #fff;
    overflow-y: auto;
    transition: width .3s;
    .logo{
      display: flex;
      align-items: center;
      font-size: 18px;
      height: 50px;
      img{
        margin: 0 16px;
        width: 32px;
        height: 32px;
      }
    }
    .nav-menu{
      height: calc(100vh-50px);
      border-right: none;
    }
    &.fold{
      width: 64px;
    }
    &.unfold{
      width: 200px;
    }
  }
  .content-right{
    margin-left: 200px;
    .nav-top{
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      padding: 0 20px;
      .nav-left{
        display: flex;
        align-items: center;
        .menu-fold{
          margin-right: 10px;
          font-size: 18px;
        }
      }
      .user-info{
        .user-icon{
          display: inline-block;
          margin-right: 15px;
        }
        .user-link{
          cursor:pointer;
          color: #409eff;
        }
      }
    }
    .wrapper{
      padding: 20px;
      background: #eef0f3;
      height: calc(100vh - 50px);
      .main-page{
         background: #fff;
         height: 100%;
      }
    }
    &.fold{
      margin-left: 64px;
    }
    &.unfold{
      margin-left: 200px;
    }
  }
}
</style>
