<template>
  <el-form ref="queryForm" :inline="true" :model="queryModel">
    <template v-for="(item,index) in form" :key="index">
      <FormItem :item="item" v-bind="item" v-model="queryModel[item.model]"/>
    </template>
    <el-form-item>
      <el-button type="primary" @click="handleQuery">查询</el-button>
      <el-button @click="handleReset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getCurrentInstance, reactive } from '@vue/runtime-core'
/**
 * form = [
 *  {
 *    type: 'input',
 *    model: 'name',
 *    label: '名称',
 *    placeeholder: '请输入xxx'
 *  }
 * ]
 */
import FormItem from './FormItem.vue'
export default {
  name: 'QueryForm',
  props: ['modelValue', 'form'],
  components: {
    FormItem
  },
  setup (props, context) {
    const porxy = getCurrentInstance()
    const form = props.form
    const queryModel = reactive({
      ...props.modelValue
    })
    const handleReset = () => {
      porxy.refs.queryForm.resetFields()
    }
    const handleQuery = () => {
      // to-do
      console.log(queryModel)
      context.emit('handleQuery', { ...queryModel })
    }
    return {
      handleQuery,
      queryModel,
      handleReset
    }
  }
}
</script>

<style>

</style>
