/**
 * 访问java接口拦截
 */
import axios from 'axios'
import storage from './storage'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getConf } from './setting'
import { getRefreshToken } from './../api/getway'

// post请求头
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'
// 设置超时
axios.defaults.timeout = 10000
axios.interceptors.request.use(
  (config) => {
    let userInfo
    let token
    if (storage.getItem('userInfo')) {
      userInfo = storage.getItem('userInfo')
      token = userInfo.access_token
    }
    // console.log(access_token)
    if (process.env.VUE_APP_ENV !== 'development') {
      config.url = process.env.VUE_APP_GETWAY_API + config.url
    } else {
      config.url = process.env.VUE_APP_PREFIX_GETWAY + config.url
    }
    // console.log(config.url)
    if (token) {
      config.headers.Authorization = 'Bearer ' + token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    // console.log(config)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  async response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
    // const res = await getRefreshToken()
    //   console.log(res)
  },
  async (err) => {
    if (err.response) {
      // console.log(err.response)
      // console.log(window.vm)
      const status = err.response.status
      const code = err.response.data.code
      const error = err.response.data.error
      console.log(code)
      if (status === 401 && code === 401) {
        const { refresh_token } = storage.getItem('userInfo')
        console.log("-------------refresh-------------")
        console.log(refresh_token)
        console.log("-------------refresh-------------")
        const conf = getConf()
        if(!refresh_token) {
          ElMessageBox.alert('登录超时，请重新登录！', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              window.vm.$store.commit('saveUserInfo', '')
              window.vm.$router.push('/login')
            },
          })
          return
        }
        const data = {
          refreshToken:refresh_token
        }
        try {
          const res = await getRefreshToken(data, conf)
          console.log('------------refreshres-------------')
          console.log(res)
          console.log('------------refreshres-------------')
          window.vm.$store.commit('saveUserInfo', res)
          err.response.config.headers.Authorization = 'bearer ' + res.access_token
          const url = err.response.config.url
          console.log(url)
          if (process.env.VUE_APP_GETWAY_API) {
            if (url.indexOf(process.env.VUE_APP_GETWAY_API) === 0) {
              err.response.config.url = url.substr(process.env.VUE_APP_GETWAY_API.length)
              console.log('------------refreshUrl---------------')
              console.log(err.response.config.url)
              console.log('------------refreshUrl---------------')
            }
          }
          return new Promise((resolve, reject) => {
            axios(err.response.config).then(res => resolve(res))// 之前是res.data
          })
        } catch (error) {
          // ElMessage.error('登录超时，请重新登录！')
          ElMessageBox.alert('登录超时，请重新登录！', '提示', {
            confirmButtonText: '确定',
            callback: () => {
              window.vm.$store.commit('saveUserInfo', '')
              window.vm.$router.push('/login')
            },
          })
        }
        // ElMessage.error(err.response.data.message)
      } else if (status === 401 && error !== '') {
        // ElMessage.error('登录超时，请重新登录！')
        // window.vm.$store.commit('saveUserInfo', '')
        // window.vm.$router.push('/login')
        ElMessageBox.alert('登录超时，请重新登录！', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            window.vm.$store.commit('saveUserInfo', '')
            window.vm.$router.push('/login')
          },
        })
      } else if (status === 403) {
        ElMessage.error('权限不够，请联系管理员授权!')
      }
      else if (status === 400) {
        ElMessage.error(err.response.data.error_description)
      }
      else {
        ElMessage.error('网络请求失败，请刷新重试!')
      }
    }
  }
)
export default {
  post (url, data) {
    // console.log(url)
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: data
      })
        .then(res => {
          // console.log(res)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  get (url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
