<template>
     <div style="height:100%">
        <iframe  :id="id" :src="url" frameborder="0" width="100%" height="100%!import" scrolling="auto"></iframe>
        <!-- <iframe  :id="id" src="https://www.baidu.com/" frameborder="0" width="100%" height="100%!import" scrolling="auto"></iframe> -->
     </div>
</template>

<script>
export default {
  name: 'Iframe',
  data () {
    return {
      url: '',
      id: '',
      token: ''
    }
  },
  created () {
    this.goUrl()
  },
  watch: {
    $route (to, from) {
      this.goUrl()
    }
  },
  methods: {
    goUrl () {
      const url = this.$route.meta.url
      console.log(url)
      this.url = url
    }
  }
}
</script>

<style>

</style>
